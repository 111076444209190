<template>
  <v-container>
    <!-- toolbar -->
    <v-row>
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Browse Travel Accommodations</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- back to browse btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/browse')"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Back to Browse</span>
          </v-tooltip>
          <!-- back to browse btn -->

          <!-- home/dashboard btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/dashboard')"
              >
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>
          <!-- home/dashboard btn -->

          <!-- dark mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>{{
                  $vuetify.theme.dark
                    ? "mdi-lightbulb-outline"
                    : "mdi-lightbulb"
                }}</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>
          <!-- dark mode button -->

          <!-- sign out btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="onSignOut"
                class="d-none d-sm-inline-flex"
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
          <!-- sign out btn -->

          <!-- notifications button -->
          <!-- <v-menu
            left
            bottom
            offset-y
            transition="slide-y-transition"
            v-if="userAccount.newDashboardMessageNotification"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="d-none d-sm-inline-flex"
              >
                <v-badge color="pink">
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-list dense :class="$vuetify.theme.dark ? '' : 'grey lighten-3'">
              <v-list-item
                :class="$vuetify.theme.dark ? '' : 'grey lighten-3'"
                link
                @click="$router.push('/dashboard')"
                v-if="userAccount.newDashboardMessageNotification"
              >
                <v-list-item-title
                  >You have a new message. Click here to review your
                  timeline.</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu> -->
          <!-- notifications button -->
        </v-toolbar>
      </v-col>
    </v-row>
    <!-- toolbar -->
    <v-row>
      <!-- description -->
      <v-col cols="12">
        <v-card outlined>
          <v-row>
            <v-col cols="12" md="6">
              <v-card-text>
                <v-card rounded elevation="0" v-if="isImageUrl">
                  <v-img
                    :src="inventoryProperty['image-src']"
                    lazy-src="@/assets/lazy-src.jpg"
                /></v-card>
                <v-card rounded elevation="0" v-else>
                  <v-carousel
                    cycle
                    height="400"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(slide, i) in inventoryProperty['image-src']"
                      :key="i"
                    >
                      <v-img
                        :src="slide.downloadUrl"
                        lazy-src="https://picsum.photos/id/11/10/6"
                      />
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <p class="title">{{ inventoryProperty.resortName }}</p>
                <p class="text-capitalize">
                  {{ inventoryProperty.destination }}
                </p>
                <p v-if="inventoryProperty.source == 'tripbeat'">
                  {{ inventoryProperty.resortAddress }}
                </p>
                <p v-else v-html="inventoryProperty.resortAddress"></p>

                <p>{{ inventoryProperty.overview }}</p>
                <p v-if="inventoryProperty.source == 'tripbeat'">
                  {{ inventoryProperty.unitInfo }}
                </p>
              </v-card-text>
            </v-col>
            <v-col cols="12" v-if="selectedInventoryListing">
              <v-alert
                type="info"
                text
                outlined
                :icon="
                  $vuetify.breakpoint.smAndDown
                    ? false
                    : 'mdi-information-outline'
                "
                class="mx-4"
                ><h4 class="mb-2">Details for this booking</h4>
                <v-row>
                  <v-col cols="12" sm="6">
                    <div>
                      Resort Name:
                      {{ selectedInventoryListing.resortName }}
                    </div>
                    <div class="text-capitalize">
                      Resort Destination:
                      {{ selectedInventoryListing.destination }}
                    </div>
                    <div class="text-capitalize">
                      Resort Address:
                      {{ inventoryProperty.resortAddress }}
                    </div>
                    <div>
                      Check In Date:
                      {{ formatDate(selectedInventoryListing.checkInDate) }}
                    </div>
                    <div>
                      Check Out Date:
                      {{ formatDate(selectedInventoryListing.checkOutDate) }}
                    </div>
                    <div>
                      Number of Nights:
                      {{ selectedInventoryListing.numberOfNights }}
                    </div>
                    <div>
                      Bedrooms: {{ selectedInventoryListing.bedroomsDisplay }}
                    </div>
                    <div class="text-capitalize">
                      Kitchen: {{ selectedInventoryListing.kitchen }}
                    </div>
                    <div v-if="selectedInventoryListing.totalSurcharges > 0">
                      Surcharges: ${{
                        selectedInventoryListing.totalSurcharges
                      }}.00
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex flex-column flex-lg-row"
                  >
                    <v-btn
                      depressed
                      class="my-2 lg-my-0 mx-md-2"
                      color="primary"
                      @click="confirmationDialog = true"
                      >Select This Booking</v-btn
                    >
                    <v-btn
                      class="my-2 lg-my-0 mx-md-2"
                      outlined
                      color="primary"
                      @click="$router.go(-1)"
                      >Back to Browse Listings</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <!-- hsi all info -->
            <v-col cols="12">
              <!-- activities -->
              <v-card-text v-if="inventoryProperty.activities">
                <h3 class="mb-6">Activities</h3>
                <div
                  v-html="inventoryProperty.activities"
                  class="gridSection"
                  v-if="typeof inventoryProperty.activities == 'string'"
                ></div>
                <div
                  id="activities"
                  v-else
                  v-for="(item, index) in inventoryProperty.activities"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-5" />
              </v-card-text>
              <!-- activities -->
              <!-- airportInfo -->
              <v-card-text v-if="inventoryProperty.airportInfo">
                <h3 class="mb-6">Airport Information</h3>
                <div
                  v-html="inventoryProperty.airportInfo"
                  id="airportInfo"
                  v-if="typeof inventoryProperty.airportInfo == 'string'"
                ></div>
                <div
                  id="airportInfo"
                  v-else
                  v-for="(item, index) in inventoryProperty.airportInfo"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-8" />
              </v-card-text>
              <!-- airportInfo -->
              <!-- amenities -->
              <v-card-text v-if="inventoryProperty.amenities">
                <h3 class="mb-6">Amenities</h3>
                <div
                  v-html="inventoryProperty.amenities"
                  class="gridSection"
                  v-if="typeof inventoryProperty.amenities == 'string'"
                ></div>
                <div
                  id="amenities"
                  v-else
                  v-for="(item, index) in inventoryProperty.amenities"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-8" />
              </v-card-text>
              <!-- amenities -->
              <!-- checkInInfo -->
              <v-card-text v-if="inventoryProperty.checkInInfo">
                <h3 class="mb-6">Check In Information</h3>
                <div
                  v-html="cleanUpCheckInInfo(inventoryProperty.checkInInfo)"
                  id="checkInInfo"
                  v-if="typeof inventoryProperty.checkInInfo == 'string'"
                ></div>
                <div
                  id="checkInInfo"
                  v-else
                  v-for="(item, index) in inventoryProperty.checkInInfo"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-8" />
              </v-card-text>
              <!-- checkInInfo -->
              <!-- otherInfo -->
              <v-card-text v-if="inventoryProperty.otherInfo">
                <h3 class="mb-6">Other Information</h3>
                <div
                  v-html="inventoryProperty.otherInfo"
                  id="otherInfo"
                  v-if="typeof inventoryProperty.otherInfo == 'string'"
                ></div>
                <div
                  id="otherInfo"
                  v-else
                  v-for="(item, index) in inventoryProperty.otherInfo"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-3" />
              </v-card-text>
              <!-- otherInfo -->
              <!-- restaurants -->
              <v-card-text v-if="inventoryProperty.restaurants">
                <h3 class="mb-6">Restaurants &amp; Dining</h3>
                <div
                  v-html="inventoryProperty.restaurants"
                  class="gridSection"
                  v-if="typeof inventoryProperty.restaurants == 'string'"
                ></div>
                <div
                  id="restaurants"
                  v-else
                  v-for="(item, index) in inventoryProperty.restaurants"
                >
                  <p>{{ item }}</p>
                </div>
                <v-divider class="mt-3" />
              </v-card-text>
              <!-- restaurants -->
              <!-- restrictions -->
              <v-card-text v-if="inventoryProperty.restrictions">
                <h3 class="mb-6">Policy Restrictions</h3>
                <div
                  v-html="inventoryProperty.restrictions"
                  id="restrictionsInfo"
                  v-if="typeof inventoryProperty.restrictions == 'string'"
                ></div>
                <div
                  id="restrictionsInfo"
                  v-else
                  v-for="(item, index) in inventoryProperty.restrictions"
                >
                  <p>{{ item }}</p>
                </div>
              </v-card-text>
              <!-- restrictions -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- description -->
    </v-row>

    <!-- confirmation dialog -->
    <v-dialog
      v-model="confirmationDialog"
      width="650"
      :overlay-opacity="0.85"
      persistent
    >
      <v-card outlined :loading="loading">
        <div v-if="!accessError && !success">
          <v-card-text class="text--primary headline font-weight-medium mt-2">
            Important: Please Read
          </v-card-text>
          <v-card-text class="body-1 text--primary">
            <p>
              Bookings through this website are not in real time and are
              <strong>subject to inventory availability.</strong>
            </p>
            <p>
              Selected inventory does not guarantee your accommodations,
              therefore
              <strong
                >do not make any additional travel arrangments, including the
                purchase of airfare or other transportation until you've
                received a confirmation from us stating that it is safe to do
                so.</strong
              >
            </p>
            <p>
              <strong>
                Once we've completed a booking, it is final. Our suppliers do
                not allow us to reschedule, change, or cancel your
                booking.</strong
              >
            </p>

            <p>
              If you have any questions or concerns, please contact us and do
              not proceed with your selection.
            </p>
            <p>
              If you'd like to proceed with your booking selection,
              <strong
                >please confirm that the information below is correct</strong
              >
              <strong v-if="selectedInventoryListing.hasSurcharges"
                >, enter your payment information,</strong
              >
              and click the select booking button.
            </p>
            <div class="font-weight-bold">
              <div>
                Resort Name:
                {{ selectedInventoryListing.resortName }}
              </div>
              <div class="text-capitalize">
                Resort Destination:
                {{ selectedInventoryListing.destination }}
              </div>
              <div class="text-capitalize">
                Resort Address:
                {{ inventoryProperty.resortAddress }}
              </div>
              <div>
                Check In Date:
                {{ formatDate(selectedInventoryListing.checkInDate) }}
              </div>
              <div>
                Check Out Date:
                {{ formatDate(selectedInventoryListing.checkOutDate) }}
              </div>
              <div>
                Number of Nights:
                {{ selectedInventoryListing.numberNights }}
              </div>
              <div>Bedrooms: {{ selectedInventoryListing.bedrooms }}</div>
              <div>Kitchen: {{ selectedInventoryListing.kitchen }}</div>
              <div v-if="selectedInventoryListing.totalSurcharges > 0">
                Surcharges: {{ selectedInventoryListing.totalSurcharges }}
              </div>
            </div>
            <!-- if surcharges payment required -->
            <div
              v-if="selectedInventoryListing.totalSurcharges > 0"
              class="border py-3 px-5 mt-8"
            >
              <p class="mt-3">
                The total surcharges for this selection are
                <strong>
                  ${{ selectedInventoryListing.totalSurcharges }}.00</strong
                >
              </p>
              <p class="mb-4">Please enter your payment information below.</p>
              <v-form ref="selectionPaymentForm" class="mb-2">
                <v-row dense>
                  <v-col cols="12" class="mb-6">
                    <v-img src="@/assets/ccLogos.jpg" :width="200" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Name on Card"
                      :rules="[textRules.required]"
                      v-model="ccData.name"
                      :loading="loading"
                      :disabled="loading"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12"
                    ><v-text-field
                      dense
                      label="Card Number"
                      :rules="[textRules.required]"
                      v-model="ccData.number"
                      :loading="loading"
                      :disabled="loading"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6"
                    ><v-select
                      v-model="ccData.expMonth"
                      :loading="loading"
                      :disabled="loading"
                      dense
                      label="Expiration Month"
                      :items="[
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12',
                      ]"
                      :rules="[textRules.required]"
                    ></v-select
                  ></v-col>
                  <v-col cols="6">
                    <v-select
                      dense
                      v-model="ccData.expYear"
                      :loading="loading"
                      :disabled="loading"
                      label="Expiration Year"
                      :items="[
                        '2022',
                        '2023',
                        '2024',
                        '2025',
                        '2026',
                        '2027',
                        '2028',
                        '2029',
                        '2030',
                        '2031',
                        '2032',
                      ]"
                      :rules="[textRules.required]"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      v-model="ccData.cvv"
                      :loading="loading"
                      :disabled="loading"
                      label="CVV"
                      :rules="[textRules.required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="ccData.zip"
                      :loading="loading"
                      :disabled="loading"
                      dense
                      label="Zip Code"
                      :rules="[textRules.required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="ccData.agreedToTerms"
                      dense
                      :rules="[checkboxRules.required]"
                      :loading="loading"
                      :disabled="loading"
                      label="I understand that these charges are non-refundable
                    regardless of whether I use my travel package or not. I
                    authorize Silent Auctions, LLC. to charge my credit card
                    provided above for total charges listed here. I understand
                    that my information will be saved to file for purchase of
                    upgrade and/or fees listed here, and card information will
                    not be saved to file for future transactions. Traveler
                    authorizes use of this credit card information and
                    guarantees this payment in full. The credit card identified
                    shall be charged the  Your Charge Total  amount stated."
                    ></v-checkbox>
                  </v-col>

                  <!-- Party Size Info -->
                  <v-row class="my-6">
                    <v-col cols="12">
                      <v-subheader class="ml-n4 font-weight-bold"
                        >Party Size Information</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <h4>Adults</h4>
                      <small class="text--disabled">(Above 12 Years)</small>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        label="Number of Adults *"
                        :items="['1', '2', '3', '4', '5', '6']"
                        :rules="[textRules.required]"
                        dense
                        v-model="selectedInventoryListing.numAdults"
                      ></v-select
                    ></v-col>
                    <v-col cols="8">
                      <h4>Children</h4>
                      <small class="text--disabled"
                        >(Age 12 Years & below)</small
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        label="Number of Children *"
                        :items="['0', '1', '2', '3', '4', '5', '6']"
                        :rules="[textRules.required]"
                        dense
                        v-model="selectedInventoryListing.numChildren"
                      ></v-select
                    ></v-col>
                    <v-col cols="8">
                      <h4>Handicap Access</h4>
                      <small class="text--disabled"
                        >(Check if handicap access is required)</small
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="Handicap Required"
                        v-model="selectedInventoryListing.handicapRequired"
                        dense
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
            </div>
            <!-- if! surcharges -->
            <v-form
              ref="partySizeForm"
              v-if="selectedInventoryListing.totalSurcharges <= 0"
              class="my-8"
            >
              <v-alert outlined :icon="false" type="info">
                <v-row>
                  <v-col cols="12">
                    <v-subheader
                      class="ml-n4 font-weight-bold text--primary body-1"
                      >Party Size Information</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <h4>Adults</h4>
                    <small class="text--disabled">(Above 12 Years)</small>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Number of Adults *"
                      :items="['1', '2', '3', '4', '5', '6']"
                      :rules="[textRules.required]"
                      dense
                      v-model="selectedInventoryListing.numAdults"
                    ></v-select
                  ></v-col>
                  <v-col cols="8">
                    <h4>Children</h4>
                    <small class="text--disabled">(Age 12 Years & below)</small>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Number of Children *"
                      :items="['0', '1', '2', '3', '4', '5', '6']"
                      :rules="[textRules.required]"
                      dense
                      v-model="selectedInventoryListing.numChildren"
                    ></v-select
                  ></v-col>
                  <v-col cols="8">
                    <h4>Handicap Access</h4>
                    <small class="text--disabled"
                      >(Check if handicap access is required)</small
                    >
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Handicap Required"
                      v-model="selectedInventoryListing.handicapRequired"
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-alert>
            </v-form>
            <p class="mt-8 font-italic red--text font-weight-medium">
              All bookings are final
            </p>

            <!-- checkbox for accepting final terms -->
            <v-checkbox
              v-model="finalTerms"
              dense
              :rules="[checkboxRules.required]"
              :loading="loading"
              :disabled="loading"
              label='No changes of any kind can be made for any reason to a confirmed reservation. This includes traveler name, travel dates, resort, or unit size. We strongly recommend that travelers protect their total investment with appropriate travel insurance coverage. Many excellent insurance providers can be found with an online search of "Travel Insurance".'
            ></v-checkbox>
          </v-card-text>
        </div>
        <div v-if="accessError && !success">
          <v-card-text>
            <v-alert outlined type="error" icon="mdi-information-outline">
              This package has either been booked, or this account is not able
              to book at this time. <br /><br />
              <a
                class="font-weight-medium"
                @click="
                  confirmationDialog = false;
                  messageDialog = true;
                "
                >Please click here to contact us if you have any questions.</a
              ><br /><br />
              Thank you.
            </v-alert>
          </v-card-text>
        </div>
        <div v-if="success">
          <v-card-text>
            <v-alert outlined type="success" icon="mdi-check-circle-outline">
              <p>You've successfully selected an inventory option.</p>

              <p>
                <strong
                  >Please do not make any additional travel arrangments,
                  including the purchase of airfare or other transportation
                  until you've received a confirmation from us stating that it
                  is safe to do so.</strong
                >
              </p>

              <p>
                Please keep an eye on your email inbox for a confirmation from
                us (usually within 3 business days).
              </p>
              <p>Thank you</p>
            </v-alert>
          </v-card-text>
        </div>
        <v-card-actions v-if="!success && !accessError" class="border pt-3">
          <v-btn
            color="error"
            text
            :loading="loading"
            :disabled="loading"
            @click="confirmationDialog = false"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="isBookNowDisabled || loading"
            @click="onSelectBooking"
            >Book Now (All Bookings are final)</v-btn
          >
        </v-card-actions>
        <v-card-actions v-if="success && !accessError">
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="onCompleteSelection"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-actions v-if="accessError">
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="$router.push('/dashboard')"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirmation dialog -->

    <!-- message dialog -->
    <v-dialog v-model="messageDialog" width="500">
      <v-card outlined :loading="loading">
        <v-card-title class="headline font-weight-medium my-3">
          Contact Us
        </v-card-title>
        <v-card-text class="text--primary body-1">
          <p>
            Please enter your message to our Booking Team below! We'll get back
            to you as soon as possible! Our team is always assisting travelers
            just like you and you can expect a response in roughly 24 to 72
            hours!
          </p>
          <p>
            Please know that we do not utilize AI and you will receive a
            response from a live member of our team to all of your inquiries!
          </p>
          <p>We look forward to hearing from you!</p>
          <p>
            <strong
              >You'll receive an email notification when we've replied.</strong
            >
          </p>
          <v-form ref="messageForm" @submit.prevent="onSendMessage">
            <v-textarea
              v-model.trim="message.message"
              :loading="loading"
              :disabled="loading"
              label="Message *"
              :rules="[textRules.required]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            depressed
            @click="messageDialog = false"
            :loading="loading"
            :disabled="loading"
            >Cancel</v-btn
          >

          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="onSendMessage"
            :disabled="loading"
            :loading="loading"
          >
            Contact Us</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- message dialog -->
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "@/plugins/firebase";
import onSignOut from "@/mixins/onSignOut";
import formatDate from "@/mixins/formatDate";
import formRules from "@/mixins/formRules";
const userAccounts = firebase.firestore().collection("userAccounts");
const inventoryProperties = firebase
  .firestore()
  .collection("inventoryProperties");

export default {
  mixins: [onSignOut, formatDate, formRules],
  data: () => ({
    userAccount: "",
    eventReport: "",
    specificWinner: "",
    inventoryProperty: "",
    isIreland: false,
    confirmationDialog: false,
    accessError: false,
    messageDialog: false,
    success: false,
    finalTerms: false,
    ccData: {
      name: "",
      number: "",
      expMonth: "",
      expYear: "",
      cvv: "",
      zip: "",
      agreedToTerms: false,
    },
    message: {
      adminActionRequired: true,
      archived: false,
      dateCreated: new Date().toISOString().substring(0, 10),
      from: "",
      message: "",
      readByAdmin: false,
      repliedToByAdmin: false,
      senderActionRequired: false,
      title: "You sent us a message",
      to: "admin",
      icon: "mdi-message-text-outline",
      color: "primary",
    },
  }),
  computed: {
    isImageUrl() {
      return typeof this.inventoryProperty["image-src"] == "string";
    },
    loading() {
      return this.$store.state.loading;
    },
    selectedInventoryListing() {
      return this.$store.state.selectedInventoryListing;
    },
    userID() {
      return this.$store.state.userID;
    },
    isBookNowDisabled() {
      return !this.finalTerms; // for disabling 'Book Now' with checkbox
    },
  },
  methods: {
    cleanUpCheckInInfo(checkInInfo) {
      if (this.inventoryProperty.source == "hsi") {
        if (checkInInfo.includes("Check-In Days:")) {
          let newCheckInInfoOne = checkInInfo.split("<p>Check-In Days:")[0];
          let newCheckInInfoTwo = checkInInfo
            .split("<p>Check-In Days:")[1]
            .split("</p>");
          newCheckInInfoTwo.shift();
          return newCheckInInfoOne + newCheckInInfoTwo;
        }
      }
      if (this.inventoryProperty.source == "tripbeat") {
        if (checkInInfo.includes("Weeks Check In Days:")) {
          let newCheckInInfoOne = checkInInfo.split(
            "<p>Weeks Check In Days:"
          )[0];
          let newCheckInInfoTwo = checkInInfo
            .split("<p>Weeks Check In Days:")[1]
            .split("</p>");
          newCheckInInfoTwo.shift();
          return newCheckInInfoOne + newCheckInInfoTwo;
        }
      }
      return checkInInfo;
    },
    async onCompleteSelection() {
      // snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Inventory Successfully Selected",
        timeout: 5000,
      });

      //redirect
      this.$router.push("/dashboard");
    },
    async onSelectBooking() {
      if (!this.finalTerms) {
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "You must accept the final terms.",
          timeout: 10000,
        });
        return;
      }

      // form error => if payment owed && !valid selectionPaymentForm
      if (
        this.selectedInventoryListing.totalSurcharges > 0 &&
        !this.$refs.selectionPaymentForm.validate()
      ) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }

      // form error => if !payment owed && !valid partySizeForm 
      if (
        this.selectedInventoryListing.totalSurcharges <= 0 &&
        !this.$refs.partySizeForm.validate()
      ) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }

      await this.$store.dispatch("setLoading", true);

      // user account is already bound
      // access checks

      // if no access, can't book, can't browse inventory, or package is already booked
      if (
        !this.userAccount.canAccess ||
        !this.userAccount.canBook ||
        !this.userAccount.canBrowseInventory ||
        this.userAccount.isConfirmed
      ) {
        await this.$store.dispatch("setLoading", false);
        this.accessError = true;
        return;
      }

      // setting isIreland
      if (this.isIreland) {
        this.selectedInventoryListing.isIreland = true;
      } else {
        this.selectedInventoryListing.isIreland = false;
      }

      // create bookings doc
      let bookingData = this.selectedInventoryListing;
      bookingData.resortAddress = this.inventoryProperty.resortAddress;
      bookingData.dateSelectedForBooking = new Date()
        .toISOString()
        .substring(0, 10);
      bookingData.dateConfirmed = "";
      bookingData.userID = this.userAccount.id;
      bookingData.packageID = this.userAccount.packageID;
      bookingData.isConfirmed = false;
      bookingData.adminNotes = "";
      bookingData.travelerNotes = "";
      bookingData.confirmationNumber = "";
      bookingData.firstName = this.userAccount.firstName;
      bookingData.lastName = this.userAccount.lastName;
      bookingData.email = this.userAccount.email;
      bookingData.archived = false;
      delete bookingData.bedroomsDisplay;
      delete bookingData.dateSubmitted;
      delete bookingData.dateUpdated;

      let docID =
        this.userAccount.packageID +
        "-" +
        (this.userAccount.numberOfInventorySelections + 1);

      let bookingsDocRef = firebase
        .firestore()
        .collection("bookingsConfirmations")
        .doc(docID);
      await bookingsDocRef.set(bookingData, { merge: true });

      // update userAccount doc => canBook, canBrowseIventory, timeline
      let timelineItem = {
        adminActionRequired: true,
        archived: false,
        dateCreated: new Date().toISOString().substring(0, 10),
        from: this.userAccount.email,
        message:
          "Please do not make additional travel arrangements until you receive a confirmation from us stating that it is safe to do so.",
        readByAdmin: true,
        repliedToByAdmin: false,
        senderActionRequired: false,
        title: "You selected an inventory option",
        to: "admin",
        icon: "mdi-map-marker-radius-outline",
        color: "warning",
      };

      let userAccountDocRef = firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id);

      await userAccountDocRef.update({
        canBook: false,
        canBrowseInventory: false,
        pendingConfirmation: true,
        pendingAVSheetCreation: false,
        timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
        numberOfInventorySelections: firebase.firestore.FieldValue.increment(1),
        dateInventorySelected: new Date().toISOString().substring(0, 10),
        inventorySelections: firebase.firestore.FieldValue.arrayUnion(docID),
        hasSelectedInventory: true,
      });

      // archive specific inventoryListing doc
      let inventoryListingsDocRef = firebase
        .firestore()
        .collection("inventoryListings")
        .doc(this.inventoryProperty.id);

      let inventoryListingsDoc = await inventoryListingsDocRef.get();
      let inventoryListingsDocData = inventoryListingsDoc.data();
      let inventoryListingsDocDataItems = inventoryListingsDocData.items;

      inventoryListingsDocDataItems.forEach((i) => {
        if (i.id == this.selectedInventoryListing.id) {
          i.archived = true;
        }
      });

      await inventoryListingsDocRef.update({
        items: inventoryListingsDocDataItems,
      });

      // add admin notification
      await firebase
        .firestore()
        .collection("adminNotifications")
        .add({
          archived: false,
          dateAdded: new Date().toISOString().substring(0, 10),
          dateArchived: "",
          text: `[New Inventory Selection - Traveler Portal] Name: ${bookingData.firstName} ${bookingData.lastName}, Email: ${bookingData.email}, Resort Name: ${this.inventoryProperty.resortName}, Destination: ${this.inventoryProperty.destination}`,
        });

      // send emails
      await axios.post("/api/inventory-selected", {
        userAccount: this.userAccount,
        bookingData: bookingData,
        ccData: this.ccData,
      });

      // if surcharges
      if (this.selectedInventoryListing.totalSurcharges > 0) {
        // send new cc info email to booking
        // await axios.post("/api/email-cc-info", {
        //   ccData: this.ccData,
        //   selectedInventoryListing: this.selectedInventoryListing,
        //   userAccount: this.userAccount,
        // });
        // add admin notification
        await firebase
          .firestore()
          .collection("adminNotifications")
          .add({
            archived: false,
            dateAdded: new Date().toISOString().substring(0, 10),
            dateArchived: "",
            text: `[Credit Card Information Entered - Traveler Portal] Name: ${bookingData.firstName} ${bookingData.lastName}, Email: ${bookingData.email}, Resort Name: ${this.inventoryProperty.resortName}, Destination: ${this.inventoryProperty.destination}, Surcharge Amount: $${this.selectedInventoryListing.totalSurcharges}`,
          });
      }

      // success
      await this.$store.dispatch("setLoading", false);
      this.success = true;
    },
    async onSendMessage() {
      if (this.$refs.messageForm.validate()) {
        this.$store.dispatch("setLoading", true);
        this.message.from = this.userAccount.email;
        let docRef = firebase
          .firestore()
          .collection("userAccounts")
          .doc(this.userAccount.id);
        await docRef.update({
          timeline: firebase.firestore.FieldValue.arrayUnion(this.message),
          nodeMailer: { created: false, newMessage: false },
        });
        this.$refs.messageForm.reset();
        this.messageDialog = false;
        this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Message sent successfully",
          timeout: 5000,
        });
        return;
      }
      this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "error",
        text: "Please check the form for errors",
        timeout: 5000,
      });
      return;
    },
  },
  watch: {
    selectedInventoryListing: {
      immediate: true,
      handler(listing) {
        if (!listing) {
          this.$router.push("/browse");
          return;
        }
      },
    },
    userID: {
      immediate: true,
      async handler(userID) {
        if (!userID) return this.$router.push("/");

        // setting variable here for steps below
        // to check for ireland package code
        let userAccount = await this.$bind(
          "userAccount",
          userAccounts.doc(userID)
        );

        // get eventReport to check for ireland package code
        let eventReport = await firebase
          .firestore()
          .collection("eventReports")
          .doc(userAccount.eventReportID)
          .get();
        eventReport = eventReport.data();
        this.eventReport = eventReport;
        // get eventReport to check for ireland package code

        // get specificWinner in eventReport winners array to check for ireland package code
        let specificWinner = eventReport.winners.filter(
          (i) => i.packageID == userAccount.packageID
        )[0];
        this.specificWinner = specificWinner;
        // get specificWinner in eventReport winners array to check for ireland package code

        // check for ireland package code
        if (this.specificWinner && this.specificWinner.packageCode) {
          let irelandPackageCodes = [
            "2d200",
            "ir2bd",
            "ircmwt",
            "lotina",
            "lotiwa",
          ];

          this.isIreland = irelandPackageCodes.some(
            (i) => i == this.specificWinner.packageCode
          );
        }
        // check for ireland package code
      },
    },
    "$route.params.id": {
      immediate: true,
      async handler(id) {
        if (id) {
          await this.$bind("inventoryProperty", inventoryProperties.doc(id));
        }
      },
    },
  },
};
</script>

<style>
.gridSection h3,
#airportInfo h3,
#checkInInfo h3,
#restrictionsInfo h3,
#otherInfo h3 {
  display: none;
}
.gridSection .info-item {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 1fr;
}

@media (min-width: 500px) {
  .gridSection .info-item {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 800px) {
  .gridSection .info-item {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

#amenities_1 ul,
#amenities_2 ul {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: auto auto auto;
  list-style-type: none;
}
#amenities_2 {
  margin-top: 30px;
}
#checkInInfo small {
  font-size: 100%;
}
#checkInInfo h5,
#amenities_1 h5,
#amenities_2 h5 {
  margin-bottom: 10px;
}
</style>
